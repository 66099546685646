import * as React from 'react';
import useId from '@mui/utils/useId';
export const useTreeViewId = ({
  params
}) => {
  const treeId = useId(params.id);
  const getTreeItemIdAttribute = React.useCallback((itemId, idAttribute) => idAttribute ?? `${treeId}-${itemId}`, [treeId]);
  return {
    getRootProps: () => ({
      id: treeId
    }),
    instance: {
      getTreeItemIdAttribute
    }
  };
};
useTreeViewId.params = {
  id: true
};