export const useTreeViewBuildContext = ({
  plugins,
  instance,
  publicAPI,
  rootRef
}) => {
  const runItemPlugins = itemPluginProps => {
    let finalRootRef = null;
    let finalContentRef = null;
    const pluginPropEnhancers = [];
    const pluginPropEnhancersNames = {};
    plugins.forEach(plugin => {
      if (!plugin.itemPlugin) {
        return;
      }
      const itemPluginResponse = plugin.itemPlugin({
        props: itemPluginProps,
        rootRef: finalRootRef,
        contentRef: finalContentRef
      });
      if (itemPluginResponse?.rootRef) {
        finalRootRef = itemPluginResponse.rootRef;
      }
      if (itemPluginResponse?.contentRef) {
        finalContentRef = itemPluginResponse.contentRef;
      }
      if (itemPluginResponse?.propsEnhancers) {
        pluginPropEnhancers.push(itemPluginResponse.propsEnhancers);

        // Prepare a list of all the slots which are enhanced by at least one plugin
        Object.keys(itemPluginResponse.propsEnhancers).forEach(propsEnhancerName => {
          pluginPropEnhancersNames[propsEnhancerName] = true;
        });
      }
    });
    const resolvePropsEnhancer = currentSlotName => currentSlotParams => {
      const enhancedProps = {};
      pluginPropEnhancers.forEach(propsEnhancersForCurrentPlugin => {
        const propsEnhancerForCurrentPluginAndSlot = propsEnhancersForCurrentPlugin[currentSlotName];
        if (propsEnhancerForCurrentPluginAndSlot != null) {
          Object.assign(enhancedProps, propsEnhancerForCurrentPluginAndSlot(currentSlotParams));
        }
      });
      return enhancedProps;
    };
    const propsEnhancers = Object.fromEntries(Object.keys(pluginPropEnhancersNames).map(propEnhancerName => [propEnhancerName, resolvePropsEnhancer(propEnhancerName)]));
    return {
      contentRef: finalContentRef,
      rootRef: finalRootRef,
      propsEnhancers
    };
  };
  const wrapItem = ({
    itemId,
    children
  }) => {
    let finalChildren = children;
    // The wrappers are reversed to ensure that the first wrapper is the outermost one.
    for (let i = plugins.length - 1; i >= 0; i -= 1) {
      const plugin = plugins[i];
      if (plugin.wrapItem) {
        finalChildren = plugin.wrapItem({
          itemId,
          children: finalChildren,
          instance
        });
      }
    }
    return finalChildren;
  };
  const wrapRoot = ({
    children
  }) => {
    let finalChildren = children;
    // The wrappers are reversed to ensure that the first wrapper is the outermost one.
    for (let i = plugins.length - 1; i >= 0; i -= 1) {
      const plugin = plugins[i];
      if (plugin.wrapRoot) {
        finalChildren = plugin.wrapRoot({
          children: finalChildren,
          instance
        });
      }
    }
    return finalChildren;
  };
  return {
    runItemPlugins,
    wrapItem,
    wrapRoot,
    instance,
    rootRef,
    publicAPI
  };
};