import React from 'react';

import {
  RecoilRoot,
} from 'recoil';

import {
  AppRoot,
} from 'ui/app';

import {
  Router,
} from 'ui/navigation';

export const App = () => {

  return (

    <React.StrictMode>

      <RecoilRoot>

        <AppRoot>

          <Router/>
        </AppRoot>
      </RecoilRoot>
    </React.StrictMode>
  );
};