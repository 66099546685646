import React from 'react';

import {
  ApiResponse,
} from 'tt-mod-frontend';

import {
  GroupPermissions,
} from 'types';

import {
  getGroupPermissions,
  getGroupPermissionsDetail,
  createGroupPermissions as apiCreateGroupPermissions,
  updateGroupPermissions as apiUpdateGroupPermissions,
} from 'api';

import {
  useUser,
} from 'hooks/useUser';

// TODO: Handle auto refresh of tokens once auth is in

interface PermissionsHookInput {
  groupPermissionsId?: string,
  runApi?: {
    groupPermissions?: boolean,
    groupPermissionsDetail?: boolean,
  },
};

interface PermissionsHookOutput {
  groupPermissionsLoading: boolean,
  groupPermissions: GroupPermissions[]|undefined,
  groupPermissionsError: any,
  refetchGroupPermissions: () => void,
  groupPermissionsDetailLoading: boolean,
  groupPermissionsDetail: GroupPermissions|undefined,
  groupPermissionsDetailError: any,
  refetchGroupPermissionsDetail: () => void,
  createGroupPermissions: ({ groupPermissionsData }: { groupPermissionsData: any }) => Promise<ApiResponse<any>>,
  updateGroupPermissions: ({ id, groupPermissionsData }: { groupPermissionsData: any, id: string }) => Promise<ApiResponse<any>>,
}

type PermissionsApiHook = (input?: PermissionsHookInput) => PermissionsHookOutput;

export const usePermissionsApi: PermissionsApiHook = ({
  groupPermissionsId,
  runApi,
} = {}) => {

  const [
    state,
    setState,
  ] = React.useState({
    groupPermissionsLoading: false,
    groupPermissions: undefined,
    groupPermissionsError: undefined,
    groupPermissionsDetailLoading: false,
    groupPermissionsDetail: undefined,
    groupPermissionsDetailError: undefined,
    currentGroupPermissionsId: undefined,
  });

  const {
    tokens,
  } = useUser();

  const fetchGroupPermissions = React.useCallback(async () => {

    setState(prevState => ({
      ...prevState,
      groupPermissions: undefined,
      groupPermissionsError: undefined,
      groupPermissionsLoading: true,
    }));

    try {

      const response = await getGroupPermissions({
        tokens,
      });

      if (response.status !== 200) {

        setState(prevState => ({
          ...prevState,
          groupPermissions: undefined,
          groupPermissionsLoading: false,
          groupPermissionsError: response.data,
        }));

        return;
      }

      setState(prevState => ({
        ...prevState,
        groupPermissions: response.data,
        groupPermissionsLoading: false,
        groupPermissionsError: undefined,
      }));
    } catch (error) {

      setState(prevState => ({
        ...prevState,
        groupPermissions: undefined,
        groupPermissionsLoading: false,
        groupPermissionsError: error,
      }));
    }
  }, [
    tokens,
  ]);

  const fetchGroupPermissionsDetail = React.useCallback(async () => {

    setState(prevState => ({
      ...prevState,
      groupPermissionsDetail: undefined,
      groupPermissionsDetailError: undefined,
      groupPermissionsDetailLoading: true,
      currentGroupPermissionsId: groupPermissionsId,
    }));

    try {

      const response = await getGroupPermissionsDetail({
        id: groupPermissionsId,
        tokens,
      });

      if (response.status !== 200) {

        setState(prevState => ({
          ...prevState,
          groupPermissionsDetail: undefined,
          groupPermissionsDetailLoading: false,
          groupPermissionsDetailError: response.data,
        }));

        return;
      }

      setState(prevState => ({
        ...prevState,
        groupPermissionsDetail: response.data,
        groupPermissionsDetailLoading: false,
        groupPermissionsDetailError: undefined,
      }));
    } catch (error) {

      setState(prevState => ({
        ...prevState,
        groupPermissionsDetail: undefined,
        groupPermissionsDetailLoading: false,
        groupPermissionsDetailError: error,
      }));
    }
  }, [
    tokens,
    groupPermissionsId,
  ]);

  const createGroupPermissions = React.useCallback(async ({
    groupPermissionsData,
  }) => {

    const response = await apiCreateGroupPermissions({
      groupPermissionsData,
      tokens,
    });

    return response;
  }, [
    tokens,
  ]);

  const updateGroupPermissions = React.useCallback(async ({
    id,
    groupPermissionsData,
  }) => {

    const response = await apiUpdateGroupPermissions({
      id,
      groupPermissionsData,
      tokens,
    });

    return response;
  }, [
    tokens,
  ]);

  React.useEffect(() => {

    if (!runApi?.groupPermissions) {
      return;
    }

    if (!!state.groupPermissions || !!state.groupPermissionsLoading || !!state.groupPermissionsError) {
      return;
    }

    fetchGroupPermissions();
  }, [
    runApi?.groupPermissions,
    state.groupPermissions,
    state.groupPermissionsError,
    state.groupPermissionsLoading,
    fetchGroupPermissions,
  ]);

  React.useEffect(() => {

    if (!runApi?.groupPermissionsDetail || !groupPermissionsId) {
      return;
    }

    if (
      (state.currentGroupPermissionsId === groupPermissionsId)
      && (!!state.groupPermissionsDetailLoading || !!state.groupPermissionsDetailError || !!state.groupPermissionsDetail)
    ) {
      return;
    }

    fetchGroupPermissionsDetail();
  }, [
    runApi?.groupPermissionsDetail,
    state.groupPermissionsDetail,
    state.groupPermissionsDetailLoading,
    state.groupPermissionsDetailError,
    state.currentGroupPermissionsId,
    groupPermissionsId,
    fetchGroupPermissionsDetail,
  ]);

  return {
    groupPermissionsLoading: state.groupPermissionsLoading,
    groupPermissions: state.groupPermissions,
    groupPermissionsError: state.groupPermissionsError,
    refetchGroupPermissions: fetchGroupPermissions,
    groupPermissionsDetailLoading: state.groupPermissionsDetailLoading,
    groupPermissionsDetail: state.groupPermissionsDetail,
    groupPermissionsDetailError: state.groupPermissionsDetailError,
    refetchGroupPermissionsDetail: fetchGroupPermissionsDetail,
    createGroupPermissions,
    updateGroupPermissions,
  };
};