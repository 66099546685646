import _extends from "@babel/runtime/helpers/esm/extends";
import * as React from 'react';
import useForkRef from '@mui/utils/useForkRef';
import { useTreeViewModels } from './useTreeViewModels';
import { TREE_VIEW_CORE_PLUGINS } from '../corePlugins';
import { extractPluginParamsFromProps } from './extractPluginParamsFromProps';
import { useTreeViewBuildContext } from './useTreeViewBuildContext';
export function useTreeViewApiInitialization(inputApiRef) {
  const fallbackPublicApiRef = React.useRef({});
  if (inputApiRef) {
    if (inputApiRef.current == null) {
      inputApiRef.current = {};
    }
    return inputApiRef.current;
  }
  return fallbackPublicApiRef.current;
}
export const useTreeView = ({
  plugins: inPlugins,
  rootRef,
  props
}) => {
  const plugins = [...TREE_VIEW_CORE_PLUGINS, ...inPlugins];
  const {
    pluginParams,
    forwardedProps,
    apiRef,
    experimentalFeatures,
    slots,
    slotProps
  } = extractPluginParamsFromProps({
    plugins,
    props
  });
  const models = useTreeViewModels(plugins, pluginParams);
  const instanceRef = React.useRef({});
  const instance = instanceRef.current;
  const publicAPI = useTreeViewApiInitialization(apiRef);
  const innerRootRef = React.useRef(null);
  const handleRootRef = useForkRef(innerRootRef, rootRef);
  const contextValue = useTreeViewBuildContext({
    plugins,
    instance,
    publicAPI,
    rootRef: innerRootRef
  });
  const [state, setState] = React.useState(() => {
    const temp = {};
    plugins.forEach(plugin => {
      if (plugin.getInitialState) {
        Object.assign(temp, plugin.getInitialState(pluginParams));
      }
    });
    return temp;
  });
  const rootPropsGetters = [];
  const runPlugin = plugin => {
    const pluginResponse = plugin({
      instance,
      params: pluginParams,
      slots,
      slotProps,
      experimentalFeatures,
      state,
      setState,
      rootRef: innerRootRef,
      models,
      plugins
    });
    if (pluginResponse.getRootProps) {
      rootPropsGetters.push(pluginResponse.getRootProps);
    }
    if (pluginResponse.publicAPI) {
      Object.assign(publicAPI, pluginResponse.publicAPI);
    }
    if (pluginResponse.instance) {
      Object.assign(instance, pluginResponse.instance);
    }
    if (pluginResponse.contextValue) {
      Object.assign(contextValue, pluginResponse.contextValue);
    }
  };
  plugins.forEach(runPlugin);
  const getRootProps = (otherHandlers = {}) => {
    const rootProps = _extends({
      role: 'tree'
    }, forwardedProps, otherHandlers, {
      ref: handleRootRef
    });
    rootPropsGetters.forEach(rootPropsGetter => {
      Object.assign(rootProps, rootPropsGetter(otherHandlers));
    });
    return rootProps;
  };
  return {
    getRootProps,
    rootRef: handleRootRef,
    contextValue,
    instance
  };
};