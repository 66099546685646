import PropTypes from 'prop-types';
import { useTreeViewContext } from '../internals/TreeViewProvider';
function TreeItem2Provider(props) {
  const {
    children,
    itemId
  } = props;
  const {
    wrapItem,
    instance
  } = useTreeViewContext();
  return wrapItem({
    children,
    itemId,
    instance
  });
}
TreeItem2Provider.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // | To update them edit the TypeScript types and run "pnpm proptypes"  |
  // ----------------------------------------------------------------------
  children: PropTypes.node,
  itemId: PropTypes.string.isRequired
};
export { TreeItem2Provider };