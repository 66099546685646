import React from 'react';

import {
  useRecoilState,
} from 'recoil';

import {
  DefaultUser,
  UserAtom,
  storeUser,
} from 'tt-mod-frontend';

export const useUser = () => {

  const [
    user,
    setUserAtom,
  ] = useRecoilState(UserAtom);

  const setUser = React.useCallback((newUser = DefaultUser) => {

    setUserAtom(newUser);
    storeUser(newUser);
  }, [
    setUserAtom,
  ]);

  return {
    tokens: user.tokens,
    loggedIn: user.loggedIn,
    setUser,
  };
};