import {
  CommonEnvironmentConfig,
} from 'tt-mod-frontend';

export const EnvironmentConfig = {
  ...CommonEnvironmentConfig,
  Authentication: {
    Issuer: 'http://localhost:3000',
    ClientId: 'test',
    ClientSecret: 'secret',
    RedirectUrl: 'http://localhost:3002',
  },
};