import React from 'react';

import {
  Outlet,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import {
  FlexDirection,
  FlexBox,
  PageHeader,
} from 'tt-mod-frontend';

import {
  Assets,
  Routes,
} from 'enums';

import {
  useUser,
} from 'hooks';

import {
  PageSidebar,
} from 'ui/components';

export const AppMenu = () => {

  const {
    pathname,
  } = useLocation();

  const {
    loggedIn,
  } = useUser();

  const navigate = useNavigate();

  React.useEffect(() => {

    if (pathname !== Routes.Default) {
      return;
    }

    const route = (loggedIn && Routes.Templates) || Routes.Login;

    navigate(route, {
      replace: true,
    });
  }, [
    loggedIn,
    pathname,
    navigate,
  ]);

  return (

    <FlexBox
      sx={{
        flex: '1',
        flexDirection: FlexDirection.Column,
        overflow: 'hidden',
      }}>

      <PageHeader
        logoSrc={Assets.Images.Logo}
        logoHeight={'48px'}/>

      <FlexBox
        sx={{
          flex: '1',
          flexDirection: FlexDirection.Row,
          overflow: 'hidden',
        }}>

        <PageSidebar/>

        <FlexBox
          sx={{
            flexDirection: FlexDirection.Column,
            flex: '1',
            overflow: 'auto',
          }}>

          <Outlet/>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
};

AppMenu.displayName = 'AppMenu';