import _extends from "@babel/runtime/helpers/esm/extends";
import * as React from 'react';
import extractEventHandlers from '@mui/utils/extractEventHandlers';
import useForkRef from '@mui/utils/useForkRef';
import { useTreeViewContext } from '../internals/TreeViewProvider';
import { useTreeItem2Utils } from '../hooks/useTreeItem2Utils';
import { TreeViewItemDepthContext } from '../internals/TreeViewItemDepthContext';
export const useTreeItem2 = parameters => {
  const {
    runItemPlugins,
    items: {
      onItemClick,
      disabledItemsFocusable,
      indentationAtItemLevel
    },
    selection: {
      multiSelect,
      disableSelection,
      checkboxSelection
    },
    expansion: {
      expansionTrigger
    },
    instance,
    publicAPI
  } = useTreeViewContext();
  const depthContext = React.useContext(TreeViewItemDepthContext);
  const {
    id,
    itemId,
    label,
    children,
    rootRef
  } = parameters;
  const {
    rootRef: pluginRootRef,
    contentRef,
    propsEnhancers
  } = runItemPlugins(parameters);
  const {
    interactions,
    status
  } = useTreeItem2Utils({
    itemId,
    children
  });
  const rootRefObject = React.useRef(null);
  const contentRefObject = React.useRef(null);
  const idAttribute = instance.getTreeItemIdAttribute(itemId, id);
  const handleRootRef = useForkRef(rootRef, pluginRootRef, rootRefObject);
  const handleContentRef = useForkRef(contentRef, contentRefObject);
  const checkboxRef = React.useRef(null);
  const createRootHandleFocus = otherHandlers => event => {
    otherHandlers.onFocus?.(event);
    if (event.defaultMuiPrevented) {
      return;
    }
    const canBeFocused = !status.disabled || disabledItemsFocusable;
    if (!status.focused && canBeFocused && event.currentTarget === event.target) {
      instance.focusItem(event, itemId);
    }
  };
  const createRootHandleBlur = otherHandlers => event => {
    otherHandlers.onBlur?.(event);
    if (event.defaultMuiPrevented) {
      return;
    }
    instance.removeFocusedItem();
  };
  const createRootHandleKeyDown = otherHandlers => event => {
    otherHandlers.onKeyDown?.(event);
    if (event.defaultMuiPrevented) {
      return;
    }
    instance.handleItemKeyDown(event, itemId);
  };
  const createContentHandleClick = otherHandlers => event => {
    otherHandlers.onClick?.(event);
    onItemClick?.(event, itemId);
    if (event.defaultMuiPrevented || checkboxRef.current?.contains(event.target)) {
      return;
    }
    if (expansionTrigger === 'content') {
      interactions.handleExpansion(event);
    }
    if (!checkboxSelection) {
      interactions.handleSelection(event);
    }
  };
  const createContentHandleMouseDown = otherHandlers => event => {
    otherHandlers.onMouseDown?.(event);
    if (event.defaultMuiPrevented) {
      return;
    }

    // Prevent text selection
    if (event.shiftKey || event.ctrlKey || event.metaKey || status.disabled) {
      event.preventDefault();
    }
  };
  const createCheckboxHandleChange = otherHandlers => event => {
    otherHandlers.onChange?.(event);
    if (event.defaultMuiPrevented) {
      return;
    }
    if (disableSelection || status.disabled) {
      return;
    }
    interactions.handleCheckboxSelection(event);
  };
  const createIconContainerHandleClick = otherHandlers => event => {
    otherHandlers.onClick?.(event);
    if (event.defaultMuiPrevented) {
      return;
    }
    if (expansionTrigger === 'iconContainer') {
      interactions.handleExpansion(event);
    }
  };
  const getRootProps = (externalProps = {}) => {
    const externalEventHandlers = _extends({}, extractEventHandlers(parameters), extractEventHandlers(externalProps));
    let ariaSelected;
    if (multiSelect) {
      ariaSelected = status.selected;
    } else if (status.selected) {
      /* single-selection trees unset aria-selected on un-selected items.
       *
       * If the tree does not support multiple selection, aria-selected
       * is set to true for the selected item and it is not present on any other item in the tree.
       * Source: https://www.w3.org/WAI/ARIA/apg/patterns/treeview/
       */
      ariaSelected = true;
    }
    const props = _extends({}, externalEventHandlers, {
      ref: handleRootRef,
      role: 'treeitem',
      tabIndex: instance.canItemBeTabbed(itemId) ? 0 : -1,
      id: idAttribute,
      'aria-expanded': status.expandable ? status.expanded : undefined,
      'aria-selected': ariaSelected,
      'aria-disabled': status.disabled || undefined
    }, externalProps, {
      onFocus: createRootHandleFocus(externalEventHandlers),
      onBlur: createRootHandleBlur(externalEventHandlers),
      onKeyDown: createRootHandleKeyDown(externalEventHandlers)
    });
    if (indentationAtItemLevel) {
      props.style = {
        '--TreeView-itemDepth': typeof depthContext === 'function' ? depthContext(itemId) : depthContext
      };
    }
    const enhancedRootProps = propsEnhancers.root?.({
      rootRefObject,
      contentRefObject,
      externalEventHandlers
    }) ?? {};
    return _extends({}, props, enhancedRootProps);
  };
  const getContentProps = (externalProps = {}) => {
    const externalEventHandlers = extractEventHandlers(externalProps);
    const props = _extends({}, externalEventHandlers, externalProps, {
      ref: handleContentRef,
      onClick: createContentHandleClick(externalEventHandlers),
      onMouseDown: createContentHandleMouseDown(externalEventHandlers),
      status
    });
    if (indentationAtItemLevel) {
      props.indentationAtItemLevel = true;
    }
    const enhancedContentProps = propsEnhancers.content?.({
      rootRefObject,
      contentRefObject,
      externalEventHandlers
    }) ?? {};
    return _extends({}, props, enhancedContentProps);
  };
  const getCheckboxProps = (externalProps = {}) => {
    const externalEventHandlers = extractEventHandlers(externalProps);
    return _extends({}, externalEventHandlers, {
      visible: checkboxSelection,
      ref: checkboxRef,
      checked: status.selected,
      disabled: disableSelection || status.disabled,
      tabIndex: -1
    }, externalProps, {
      onChange: createCheckboxHandleChange(externalEventHandlers)
    });
  };
  const getLabelProps = (externalProps = {}) => {
    const externalEventHandlers = _extends({}, extractEventHandlers(externalProps));
    return _extends({}, externalEventHandlers, {
      children: label
    }, externalProps);
  };
  const getIconContainerProps = (externalProps = {}) => {
    const externalEventHandlers = extractEventHandlers(externalProps);
    return _extends({}, externalEventHandlers, externalProps, {
      onClick: createIconContainerHandleClick(externalEventHandlers)
    });
  };
  const getGroupTransitionProps = (externalProps = {}) => {
    const externalEventHandlers = extractEventHandlers(externalProps);
    const response = _extends({}, externalEventHandlers, {
      unmountOnExit: true,
      component: 'ul',
      role: 'group',
      in: status.expanded,
      children
    }, externalProps);
    if (indentationAtItemLevel) {
      response.indentationAtItemLevel = true;
    }
    return response;
  };
  const getDragAndDropOverlayProps = (externalProps = {}) => {
    const externalEventHandlers = _extends({}, extractEventHandlers(externalProps));
    const enhancedDragAndDropOverlayProps = propsEnhancers.dragAndDropOverlay?.({
      rootRefObject,
      contentRefObject,
      externalEventHandlers
    }) ?? {};
    return _extends({}, externalProps, enhancedDragAndDropOverlayProps);
  };
  return {
    getRootProps,
    getContentProps,
    getGroupTransitionProps,
    getIconContainerProps,
    getCheckboxProps,
    getLabelProps,
    getDragAndDropOverlayProps,
    rootRef: handleRootRef,
    status,
    publicAPI
  };
};