import { useTreeViewContext } from '../internals/TreeViewProvider';
export function useTreeItemState(itemId) {
  const {
    instance,
    items: {
      onItemClick
    },
    selection: {
      multiSelect,
      checkboxSelection,
      disableSelection
    },
    expansion: {
      expansionTrigger
    }
  } = useTreeViewContext();
  const expandable = instance.isItemExpandable(itemId);
  const expanded = instance.isItemExpanded(itemId);
  const focused = instance.isItemFocused(itemId);
  const selected = instance.isItemSelected(itemId);
  const disabled = instance.isItemDisabled(itemId);
  const handleExpansion = event => {
    if (!disabled) {
      if (!focused) {
        instance.focusItem(event, itemId);
      }
      const multiple = multiSelect && (event.shiftKey || event.ctrlKey || event.metaKey);

      // If already expanded and trying to toggle selection don't close
      if (expandable && !(multiple && instance.isItemExpanded(itemId))) {
        instance.toggleItemExpansion(event, itemId);
      }
    }
  };
  const handleSelection = event => {
    if (!disabled) {
      if (!focused) {
        instance.focusItem(event, itemId);
      }
      const multiple = multiSelect && (event.shiftKey || event.ctrlKey || event.metaKey);
      if (multiple) {
        if (event.shiftKey) {
          instance.expandSelectionRange(event, itemId);
        } else {
          instance.selectItem({
            event,
            itemId,
            keepExistingSelection: true
          });
        }
      } else {
        instance.selectItem({
          event,
          itemId,
          shouldBeSelected: true
        });
      }
    }
  };
  const handleCheckboxSelection = event => {
    if (disableSelection || disabled) {
      return;
    }
    const hasShift = event.nativeEvent.shiftKey;
    if (multiSelect && hasShift) {
      instance.expandSelectionRange(event, itemId);
    } else {
      instance.selectItem({
        event,
        itemId,
        keepExistingSelection: multiSelect,
        shouldBeSelected: event.target.checked
      });
    }
  };
  const preventSelection = event => {
    if (event.shiftKey || event.ctrlKey || event.metaKey || disabled) {
      // Prevent text selection
      event.preventDefault();
    }
  };
  return {
    disabled,
    expanded,
    selected,
    focused,
    disableSelection,
    checkboxSelection,
    handleExpansion,
    handleSelection,
    handleCheckboxSelection,
    handleContentClick: onItemClick,
    preventSelection,
    expansionTrigger
  };
}