import React from 'react';

import {
  useNavigate,
} from 'react-router-dom';

import {
  Assets,
  Routes,
} from 'enums';

import {
  FlexBox,
  PageHeader,
  PrimaryButton,
  Divider,
  Caption,
  CaptionLink,
  useTheme,
  Heading2,
  useModal,
} from 'tt-mod-frontend';

import {
  useAuth,
} from 'hooks';

// TODO: Loading state
export const Login = () => {

  const {
    palette,
  } = useTheme();

  const {
    login,
    getAuthCodeFromSearchParams,
    clearAuthCode,
  } = useAuth();

  const {
    showErrorModal,
  } = useModal();

  const navigate = useNavigate();

  const onLoginClick = React.useCallback(() => {

    // login()
    //   .catch(() => {

    //     showErrorModal({
    //       message: 'Login failed. Please try again.'
    //     });
    //   });

    navigate(Routes.Templates, {
      replace: true,
    });
  }, [
    navigate,
    // login,
    // showErrorModal,
  ]);

  React.useEffect(() => {

    const authCode = getAuthCodeFromSearchParams();

    if (!authCode) {
      return;
    }

    login()
      .then(() => {

        navigate(Routes.Templates, {
          replace: true,
        });
      })
      .catch(() => {

        clearAuthCode();

        showErrorModal({
          message: 'Login failed. Please try again.'
        });
      });
  }, [
    login,
    navigate,
    clearAuthCode,
    getAuthCodeFromSearchParams,
    showErrorModal,
  ]);

  return (

    <FlexBox
      sx={{
        flex: '1',
      }}>

      <PageHeader
        logoSrc={Assets.Images.Logo}
        logoHeight={'48px'}/>

      <FlexBox
        sx={{
          flex: '1',
          justifyContent: 'center',
          alignItems: 'center',
        }}>

        <FlexBox
          sx={{
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: palette.divider,
            borderRadius: '8px',
            padding: 2,
            minWidth: '450px',
            minHeight: '300px',
          }}>

          <Heading2
            sx={{
              fontWeight: 'bold',
            }}>

            Login
          </Heading2>

          <Caption
            sx={{
              marginY: 2,
            }}>

            Welcome to Template Tool. Sign in below
          </Caption>

          <PrimaryButton
            sx={{
              marginX: 'auto',
              marginY: 2,
              width: '320px',
            }}
            onClick={onLoginClick}>

            Continue with SSO Login
          </PrimaryButton>

          <FlexBox
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              marginY: 2,
            }}>

            <Divider
              color={palette.common.black}
              sx={{
                flex: '1',
              }}/>

            <Caption
              sx={{
                marginX: 2,
              }}>

              Or
            </Caption>

            <Divider
              color={palette.common.black}
              sx={{
                flex: '1',
              }}/>
          </FlexBox>

          <FlexBox
            sx={{
              flexDirection: 'row',
            }}>

            <Caption>

              New to Template Tool?
            </Caption>

            <CaptionLink
              sx={{
                marginLeft: 1,
              }}>

              Contact Us
            </CaptionLink>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
};

Login.displayName = 'Login';