import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["slots", "slotProps", "apiRef", "experimentalFeatures"];
export const extractPluginParamsFromProps = _ref => {
  let {
      props: {
        slots,
        slotProps,
        apiRef,
        experimentalFeatures
      },
      plugins
    } = _ref,
    props = _objectWithoutPropertiesLoose(_ref.props, _excluded);
  const paramsLookup = {};
  plugins.forEach(plugin => {
    Object.assign(paramsLookup, plugin.params);
  });
  const pluginParams = {};
  const forwardedProps = {};
  Object.keys(props).forEach(propName => {
    const prop = props[propName];
    if (paramsLookup[propName]) {
      pluginParams[propName] = prop;
    } else {
      forwardedProps[propName] = prop;
    }
  });
  const defaultizedPluginParams = plugins.reduce((acc, plugin) => {
    if (plugin.getDefaultizedParams) {
      return plugin.getDefaultizedParams(acc);
    }
    return acc;
  }, pluginParams);
  return {
    apiRef,
    forwardedProps,
    pluginParams: defaultizedPluginParams,
    slots: slots ?? {},
    slotProps: slotProps ?? {},
    experimentalFeatures: experimentalFeatures ?? {}
  };
};