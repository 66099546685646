import React from 'react';

import Editor, {
  useMonaco,
} from '@monaco-editor/react';

import {
  useParams,
  useNavigate,
  generatePath,
} from 'react-router-dom';

import {
  BasePage,
  CollapsibleCard,
  Dropdown,
  FlexBox,
  Paragraph,
  PrimaryButton,
  SecondaryButton,
  TextInput,
  useModal,
} from 'tt-mod-frontend';

import {
  TemplateContextFormatSchema,
} from 'schemas';

import {
  Routes,
} from 'enums';

import {
  useTemplateApi,
} from 'hooks';

const EnabledDropdownOptions = [{
  optionTitle: 'Enabled',
  optionValue: true,
}, {
  optionTitle: 'Disabled',
  optionValue: false,
}];

const InputTitleProps = {
  sx: {
    flex: '1',
  },
};

const InputProps = {
  sx: {
    flex: '3',
  },
};

const InputContainerProps = {
  sx: {
    margin: 1,
    flexDirection: 'row',
    alignItems: 'center',
  }
};

const MonacoEditorOptions = {
  scrollbar: {
    alwaysConsumeMouseWheel: false,
  },
};

// TODO: Validate template by uploading dummy data
// TODO: Save anyway modal if validation fails
export const TemplateEditor = () => {

  const [
    state,
    setState,
  ] = React.useState({
    templateName: '',
    templateDescription: '',
    templateEnabled: true,
    templateGroupPath: '',
    contextFormat: '',
    stylesheet: '',
    content: '',
    headerTemplate: '',
    footerTemplate: '',
    fileNameTemplate: '',
    submitLoading: false,
  });

  const {
    templateId,
  } = useParams();

  const navigate = useNavigate();

  const monaco = useMonaco();

  const {
    showErrorModal,
    showInformationModal,
  } = useModal();

  const {
    templateDetail,
    templateDetailError,
    templateDetailLoading,
    refetchTemplateDetail,
    createTemplate,
    updateTemplate,
  } = useTemplateApi({
    runApi: {
      templateDetail: !!templateId,
    },
    templateId,
  });

  const onTemplateNameChange = React.useCallback(newValue => {

    setState(prevState => ({
      ...prevState,
      templateName: newValue,
    }));
  }, []);

  const onTemplateDescriptionChange = React.useCallback(newValue => {

    setState(prevState => ({
      ...prevState,
      templateDescription: newValue,
    }));
  }, []);

  const onTemplatedEnabledChange = React.useCallback(newValue => {

    setState(prevState => ({
      ...prevState,
      templateEnabled: newValue,
    }));
  }, []);

  const onTemplateGroupPathChange = React.useCallback(newValue => {

    setState(prevState => ({
      ...prevState,
      templateGroupPath: newValue,
    }));
  }, []);

  const onContextFormatChange = React.useCallback(newValue => {

    setState(prevState => ({
      ...prevState,
      contextFormat: newValue,
    }));
  }, []);

  const onContentChange = React.useCallback(newValue => {

    setState(prevState => ({
      ...prevState,
      content: newValue,
    }));
  }, []);

  const onHeaderTemplateChange = React.useCallback(newValue => {

    setState(prevState => ({
      ...prevState,
      headerTemplate: newValue,
    }));
  }, []);

  const onFooterTemplateChange = React.useCallback(newValue => {

    setState(prevState => ({
      ...prevState,
      footerTemplate: newValue,
    }));
  }, []);

  const onFileNameTemplateChange = React.useCallback(newValue => {

    setState(prevState => ({
      ...prevState,
      fileNameTemplate: newValue,
    }));
  }, []);

  const onStylesheetChange = React.useCallback(newValue => {

    setState(prevState => ({
      ...prevState,
      stylesheet: newValue,
    }));
  }, []);

  const onSaveChangesClick = React.useCallback(async () => {

    let contextFormatJson = undefined;

    try {

      contextFormatJson = JSON.parse(state.contextFormat);
    } catch (error) {

      showErrorModal({
        message: 'An error occurred while parsing the context format JSON. Please verify that the JSON is valid before saving the template.',
      });

      return;
    }

    try {

      const templateData = {
        name: state.templateName,
        description: state.templateDescription,
        enabled: state.templateEnabled,
        folderPath: state.templateGroupPath,
        sourceTemplate: state.content,
        headerTemplate: state.headerTemplate,
        footerTemplate: state.footerTemplate,
        fileNameTemplate: state.fileNameTemplate,
        stylesheet: state.stylesheet,
        contextFormat: {
          sections: contextFormatJson,
        },
      };

      setState(prevState => ({
        ...prevState,
        submitLoading: true,
      }));

      await updateTemplate({
        id: templateId,
        templateData,
      });

      setState(prevState => ({
        ...prevState,
        submitLoading: false,
      }));

      showInformationModal({
        heading: 'Template Updated',
        message: 'The template has been updated successfully.',
        secondaryButtonText: 'Confirm',
      });
    } catch (error) {

      setState(prevState => ({
        ...prevState,
        submitLoading: false,
      }));

      showErrorModal({
        message: 'An error occurred while updating the template. Please verify the template and try again.',
      });
    }
  }, [
    templateId,
    state.content,
    state.contextFormat,
    state.headerTemplate,
    state.footerTemplate,
    state.fileNameTemplate,
    state.stylesheet,
    state.templateName,
    state.templateDescription,
    state.templateEnabled,
    state.templateGroupPath,
    updateTemplate,
    showInformationModal,
    showErrorModal,
  ]);

  const onCreateTemplateClick = React.useCallback(async () => {

    let contextFormatJson = undefined;

    try {

      contextFormatJson = JSON.parse(state.contextFormat);
    } catch (error) {

      showErrorModal({
        message: 'An error occurred while parsing the context format JSON. Please verify that the JSON is valid before submitting the template.',
      });

      return;
    }

    try {

      const templateData = {
        name: state.templateName,
        description: state.templateDescription,
        enabled: state.templateEnabled,
        folderPath: state.templateGroupPath,
        stylesheet: state.stylesheet,
        sourceTemplate: state.content,
        headerTemplate: state.headerTemplate,
        footerTemplate: state.footerTemplate,
        fileNameTemplate: state.fileNameTemplate,
        contextFormat: {
          sections: contextFormatJson,
        },
      };

      setState(prevState => ({
        ...prevState,
        submitLoading: true,
      }));

      const creationResult = await createTemplate({
        templateData,
      });

      setState(prevState => ({
        ...prevState,
        submitLoading: false,
      }));

      showInformationModal({
        heading: 'Template Created',
        message: 'The template has been created successfully.',
        secondaryButtonText: 'Confirm',
        onSecondaryClick: () => {
          navigate(generatePath(Routes.TemplateEditor, {
            templateId: creationResult.data.id,
          }), {
            replace: true,
          });
        },
      });
    } catch {

      setState(prevState => ({
        ...prevState,
        submitLoading: false,
      }));

      showErrorModal({
        message: 'An error occurred while creating the template. Please verify the template and try again.',
      });
    }
  }, [
    state.content,
    state.contextFormat,
    state.headerTemplate,
    state.footerTemplate,
    state.fileNameTemplate,
    state.stylesheet,
    state.templateName,
    state.templateDescription,
    state.templateEnabled,
    state.templateGroupPath,
    navigate,
    createTemplate,
    showInformationModal,
    showErrorModal,
  ]);

  const onTestTemplateClick = React.useCallback(() => {

    // TODO: Test will need to have its own endpoint
  }, [

  ]);

  const buttons = React.useMemo(() => {

    const onPrimaryClick = !!templateId
      ? onSaveChangesClick
      : onCreateTemplateClick;

    return (

      <FlexBox
        sx={{
          flexDirection: 'row',
          gap: 1,
        }}>

        { !!templateId &&

          <SecondaryButton
            onClick={onTestTemplateClick}>

            Test Template
          </SecondaryButton>
        }

        <PrimaryButton
          onClick={onPrimaryClick}
          disabled={state.submitLoading}>

          { (!templateId && 'Create Template') || 'Save Changes' }
        </PrimaryButton>
      </FlexBox>
    );
  }, [
    templateId,
    state.submitLoading,
    onSaveChangesClick,
    onCreateTemplateClick,
    onTestTemplateClick,
  ]);

  React.useEffect(() => {

    if (
      !!state.templateName
      || !!state.templateDescription
      || !!state.templateGroupPath
      || !!state.content
      || !!state.headerTemplate
      || !!state.footerTemplate
      || !!state.fileNameTemplate
      || !!state.contextFormat
      || !!state.stylesheet
    ) {
      return;
    }

    if (templateDetailLoading || !templateDetail || !!templateDetailError) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      templateName: templateDetail.name,
      templateDescription: templateDetail.description,
      templateEnabled: templateDetail.enabled,
      templateGroupPath: templateDetail.folderPath,
      headerTemplate: templateDetail.headerTemplate,
      footerTemplate: templateDetail.footerTemplate,
      fileNameTemplate: templateDetail.fileNameTemplate,
      stylesheet: templateDetail.stylesheet,
      content: templateDetail.sourceTemplate,
      contextFormat: JSON.stringify(templateDetail?.contextFormat?.sections, null, 4),
    }));
  }, [
    templateDetailLoading,
    templateDetail,
    templateDetailError,
    state.templateName,
    state.templateDescription,
    state.templateGroupPath,
    state.content,
    state.headerTemplate,
    state.footerTemplate,
    state.fileNameTemplate,
    state.stylesheet,
    state.contextFormat,
  ]);

  React.useEffect(() => {

    monaco?.languages?.json?.jsonDefaults?.setDiagnosticsOptions({
      validate: true,
      schemas: [{
        uri: '',
        fileMatch: ['*'],
        schema: TemplateContextFormatSchema,
      }],
    });
  }, [
    monaco,
  ]);

  return (

    <BasePage
      heading={(!templateId && 'New Template') || `Templates / ${templateDetail?.name}`}
      subHeading={
        !templateId
          ? 'Create a new template.'
          : 'Make changes to the selected template.'
      }
      loading={templateDetailLoading || state.submitLoading}
      loadingText={(state.submitLoading && 'Submitting Template...') || 'Loading Template...'}
      error={templateDetailError}
      onErrorRetry={refetchTemplateDetail}
      rightComponent={buttons}>

      <CollapsibleCard
        header={'Basic Details'}
        sx={{
          marginBottom: 2,
        }}>

        <FlexBox
          sx={{
            paddingY: 1,
          }}>

          <FlexBox
            {...InputContainerProps}>

            <Paragraph
              {...InputTitleProps}>

              Template Name
            </Paragraph>

            <TextInput
              {...InputProps}
              value={state.templateName}
              onChange={onTemplateNameChange}/>
          </FlexBox>

          <FlexBox
            {...InputContainerProps}>

            <Paragraph
              {...InputTitleProps}>

              Description
            </Paragraph>

            <TextInput
              {...InputProps}
              value={state.templateDescription}
              onChange={onTemplateDescriptionChange}/>
          </FlexBox>

          <FlexBox
            {...InputContainerProps}>

            <Paragraph
              {...InputTitleProps}>

              Enabled
            </Paragraph>

            <Dropdown
              {...InputProps}
              options={EnabledDropdownOptions}
              value={state.templateEnabled}
              onChange={onTemplatedEnabledChange}/>
          </FlexBox>

          <FlexBox
            {...InputContainerProps}>

            <Paragraph
              {...InputTitleProps}>

              Group Path
            </Paragraph>

            <TextInput
              {...InputProps}
              value={state.templateGroupPath}
              onChange={onTemplateGroupPathChange}/>
          </FlexBox>
        </FlexBox>
      </CollapsibleCard>

      <CollapsibleCard
        header={'Content'}
        sx={{
          marginBottom: 2,
        }}>

        <Editor
          height={'500px'}
          defaultLanguage={'html'}
          value={state.content}
          options={MonacoEditorOptions}
          onChange={onContentChange}/>
      </CollapsibleCard>

      <CollapsibleCard
        header={'File Name'}
        sx={{
          marginBottom: 2,
        }}>

        <Editor
          height={'50px'}
          value={state.fileNameTemplate}
          options={MonacoEditorOptions}
          onChange={onFileNameTemplateChange}/>
      </CollapsibleCard>

      <CollapsibleCard
        header={'Header'}
        sx={{
          marginBottom: 2,
        }}>

        <Editor
          height={'500px'}
          value={state.headerTemplate}
          options={MonacoEditorOptions}
          onChange={onHeaderTemplateChange}/>
      </CollapsibleCard>

      <CollapsibleCard
        header={'Footer'}
        sx={{
          marginBottom: 2,
        }}>

        <Editor
          height={'500px'}
          value={state.footerTemplate}
          options={MonacoEditorOptions}
          onChange={onFooterTemplateChange}/>
      </CollapsibleCard>

      <CollapsibleCard
        header={'Context Format'}
        sx={{
          marginBottom: 2,
        }}>

        <Editor
          height={'500px'}
          defaultLanguage={'json'}
          value={state.contextFormat}
          options={MonacoEditorOptions}
          onChange={onContextFormatChange}/>
      </CollapsibleCard>

      <CollapsibleCard
        header={'Custom Stylesheet'}
        sx={{
          marginBottom: 2,
        }}>

        <Editor
          height={'500px'}
          defaultLanguage={'css'}
          value={state.stylesheet}
          options={MonacoEditorOptions}
          onChange={onStylesheetChange}/>
      </CollapsibleCard>
    </BasePage>
  );
};

TemplateEditor.displayName = 'TemplateEditor';