import React from "react";

import {
  AuthorizationServer,
  Client,
  discoveryRequest,
  processDiscoveryResponse,
  authorizationCodeGrantRequest,
  validateAuthResponse,
  isOAuth2Error,
  processAuthorizationCodeOpenIDResponse,
  refreshTokenGrantRequest,
  processRefreshTokenResponse,
  revocationRequest,
  processRevocationResponse,
  skipStateCheck,
} from 'oauth4webapi';

import {
  EnvironmentConfig,
} from 'config';

import {
  useUser,
} from 'hooks/useUser';

const getAuthCodeFromSearchParams = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const result: string = searchParams.get('code');
  return result;
};

const clearAuthCode = () => {
  const url = new URL(window.location.href);
  url.searchParams.delete('code');
  window.history.replaceState(null, null, url);
};

const issuer = new URL(EnvironmentConfig.Authentication.Issuer);
const redirectUrl = EnvironmentConfig.Authentication.RedirectUrl;

const client: Client = {
  client_id: EnvironmentConfig.Authentication.ClientId,
  client_secret: EnvironmentConfig.Authentication.ClientSecret,
};

let loginPromise: Promise<any> = undefined;

// TODO: Code verifier / PKCE
// TODO: Move into tt-mod-frontend
export const useAuth = () => {

  const {
    loggedIn,
    tokens,
    setUser,
  } = useUser();

  const discover = React.useCallback(async () => {

    const response = await discoveryRequest(issuer);
    const discoveryResult = await processDiscoveryResponse(issuer, response);

    return discoveryResult;
  }, []);

  const loginWithRedirect = React.useCallback(async (authServer: AuthorizationServer) => {

    const authUrl = new URL(authServer.authorization_endpoint);
    authUrl.searchParams.set('client_id', client.client_id);
    authUrl.searchParams.set('redirect_uri', redirectUrl);
    authUrl.searchParams.set('response_type', 'code');

    window.location.assign(authUrl);
  }, []);

  const loginWithAuthCode = React.useCallback(async (authServer: AuthorizationServer) => {

    const currentUrl = new URL(window.location.href);
    const params = validateAuthResponse(authServer, client, currentUrl, skipStateCheck);

    if (isOAuth2Error(params)) {
      console.error('Error while logging in', params);
      throw params;
    }

    const response = await authorizationCodeGrantRequest(
      authServer,
      client,
      params,
      redirectUrl,
      'none'
    );

    // TODO: Remove expected id token nonce or implement proper PKCE
    const result = await processAuthorizationCodeOpenIDResponse(authServer, client, response, 'null');

    if (isOAuth2Error(result)) {
      console.error('Error while logging in', result);
      throw result;
    }

    setUser({
      loggedIn: true,
      tokens: {
        access: result.access_token,
        id: result.id_token,
        refresh: result.refresh_token,
      },
    });

    clearAuthCode();

    return result;
  }, [
    setUser,
  ]);

  const login = React.useCallback(async (forceNewLogin = false) => {

    if (loggedIn) {
      return;
    }

    const authServer = await discover();

    if (!!loginPromise && !forceNewLogin) {
      return loginPromise;
    }

    const authCode = getAuthCodeFromSearchParams();

    if (!authCode) {
      loginPromise = loginWithRedirect(authServer);
      return loginPromise;
    }

    loginPromise = loginWithAuthCode(authServer);
    return loginPromise;
  }, [
    loggedIn,
    discover,
    loginWithRedirect,
    loginWithAuthCode,
  ]);

  const refresh = React.useCallback(async () => {

    if (!loggedIn) {
      throw new Error('Attempted to refresh but user is not logged in');
    }

    const authServer = await discover();

    const response = await refreshTokenGrantRequest(
      authServer,
      client,
      tokens?.refresh
    );

    const result = await processRefreshTokenResponse(authServer, client, response);

    if (isOAuth2Error(result)) {
      console.error('Error while refreshing tokens', result);
      throw result;
    }

    return {
      ...result,
      refresh_token: result.refresh_token ?? tokens?.refresh,
    };
  }, [
    loggedIn,
    tokens,
    discover,
  ]);

  const logout = React.useCallback(async () => {

    if (!loggedIn) {
      throw new Error('Attempted to logout but user is not logged in');
    }

    const authServer = await discover();

    const response = await revocationRequest(
      authServer,
      client,
      tokens?.refresh,
    );

    const result = await processRevocationResponse(response);

    if (isOAuth2Error(result)) {
      console.error('Error while logging out', result);
      throw result;
    }

    return result;
  }, [
    tokens,
    loggedIn,
    discover,
  ]);

  return {
    discover,
    login,
    refresh,
    logout,
    getAuthCodeFromSearchParams,
    clearAuthCode,
  };
};