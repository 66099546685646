import { useTreeViewContext } from '../../internals/TreeViewProvider';
const isItemExpandable = reactChildren => {
  if (Array.isArray(reactChildren)) {
    return reactChildren.length > 0 && reactChildren.some(isItemExpandable);
  }
  return Boolean(reactChildren);
};

/**
 * Plugins that need to be present in the Tree View in order for `useTreeItem2Utils` to work correctly.
 */

/**
 * Plugins that `useTreeItem2Utils` can use if they are present, but are not required.
 */

export const useTreeItem2Utils = ({
  itemId,
  children
}) => {
  const {
    instance,
    selection: {
      multiSelect
    }
  } = useTreeViewContext();
  const status = {
    expandable: isItemExpandable(children),
    expanded: instance.isItemExpanded(itemId),
    focused: instance.isItemFocused(itemId),
    selected: instance.isItemSelected(itemId),
    disabled: instance.isItemDisabled(itemId)
  };
  const handleExpansion = event => {
    if (status.disabled) {
      return;
    }
    if (!status.focused) {
      instance.focusItem(event, itemId);
    }
    const multiple = multiSelect && (event.shiftKey || event.ctrlKey || event.metaKey);

    // If already expanded and trying to toggle selection don't close
    if (status.expandable && !(multiple && instance.isItemExpanded(itemId))) {
      instance.toggleItemExpansion(event, itemId);
    }
  };
  const handleSelection = event => {
    if (status.disabled) {
      return;
    }
    if (!status.focused) {
      instance.focusItem(event, itemId);
    }
    const multiple = multiSelect && (event.shiftKey || event.ctrlKey || event.metaKey);
    if (multiple) {
      if (event.shiftKey) {
        instance.expandSelectionRange(event, itemId);
      } else {
        instance.selectItem({
          event,
          itemId,
          keepExistingSelection: true
        });
      }
    } else {
      instance.selectItem({
        event,
        itemId,
        shouldBeSelected: true
      });
    }
  };
  const handleCheckboxSelection = event => {
    const hasShift = event.nativeEvent.shiftKey;
    if (multiSelect && hasShift) {
      instance.expandSelectionRange(event, itemId);
    } else {
      instance.selectItem({
        event,
        itemId,
        keepExistingSelection: multiSelect,
        shouldBeSelected: event.target.checked
      });
    }
  };
  const interactions = {
    handleExpansion,
    handleSelection,
    handleCheckboxSelection
  };
  return {
    interactions,
    status
  };
};